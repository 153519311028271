import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import RichText, { domToReact } from '@/components/RichText/RichText';
import StrapiImage from '@/components/StrapiImage/StrapiImage';
import { trackProductTileClick } from '@/helpers/dataLayer';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import {
  Image,
  ImageWrapper,
  Label,
  LabelWrapper,
  Wrapper,
} from './ProductTile.style';

const ProductTile = ({
  className,
  image,
  url,
  space,
  name,
  imageComponentType,
}) => {
  const mobileMediaQuery = useMobileMediaQuery();

  const richTextReplacer = (element, wrappedReplacer) => {
    if (element.name === 'h2' && element.children.length) {
      return <>{domToReact(element.children, { replace: wrappedReplacer })}</>;
    }

    return null;
  };

  return (
    <Wrapper
      className={className}
      isDouble={space === 2}
      data-cy={`product tile: ${url}`}>
      <Link
        href={url}
        className="mrkt-hp_komplexe-mietvorhaben"
        onClick={trackProductTileClick}>
        <ImageWrapper>
          {imageComponentType === 'next' ? (
            <Image
              src={image.data.url}
              alt={image.data.alternativeText}
              height={image.data.height}
              width={image.data.width}
            />
          ) : (
            <StrapiImage data={image.data} />
          )}
        </ImageWrapper>
        <LabelWrapper>
          <Label
            component="span"
            variant={mobileMediaQuery ? 'body2' : 'h4'}
            align="center">
            <RichText data={name} replace={richTextReplacer} />
          </Label>
        </LabelWrapper>
      </Link>
    </Wrapper>
  );
};

ProductTile.propTypes = {
  /** className to be used on the wrapper */
  className: PropTypes.string,
  /** Content displayed in orange box. */
  name: PropTypes.string.isRequired,
  /** Image. */
  image: PropTypes.shape({
    dimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    copyright: PropTypes.string,
    alt: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  /** Url path to go on click, for example "/krane". */
  url: PropTypes.string,
  /** Space the tile occupies */
  space: PropTypes.number,
  imageComponentType: PropTypes.oneOf(['strapi', 'next']),
};

ProductTile.defaultProps = {
  className: '',
  space: 1,
  url: '/',
  imageComponentType: 'strapi',
};

export default ProductTile;
