import { Paper, TableContainer } from '@mui/material';
import { attributesToProps, domToReact } from 'html-react-parser';
import Link from 'next/link';

import { getAbsoluteImageUrl } from '@/helpers/strapi/helpers';
import theme from '@/styles/theme';

import Button from '../ui/Button/Button';
import { Table } from './RichText.style';

function renderNative(element, wrappedReplacer) {
  const HtmlElement = element.name;

  if (element.children.length) {
    return (
      <HtmlElement
        data-cy={`rich-text-native-element-${element.name?.toLowerCase()}`}>
        {domToReact(element.children, { replace: wrappedReplacer })}
      </HtmlElement>
    );
  }
}

const elementsToHandleNatively = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'strong',
  'italic',
  'p',
  'ul',
  'li',
];

function prependBaseUrlInSrcSet(attribs) {
  const { srcset, ...rest } = attribs;

  if (srcset) {
    const srcsetParts = srcset.split(',');
    const notEmptySrcsetParts = srcsetParts.filter(Boolean);
    const srcsetPartsWithAbsoluteUrls = notEmptySrcsetParts.map(srcsetPart => {
      const [src, ...rest] = srcsetPart.split(' ');

      return `${getAbsoluteImageUrl(src)} ${rest.join(' ')}`;
    });
    const computedSrcset = srcsetPartsWithAbsoluteUrls.join(',');

    return {
      srcSet: computedSrcset,
      ...rest,
    };
  }

  return attribs;
}

export function richTextReplacer(element, wrappedReplacer) {
  if (
    element.name === 'img' &&
    element.attribs.src &&
    !element.attribs.src.startsWith('http')
  ) {
    const computedAttributes = prependBaseUrlInSrcSet(element.attribs);
    const props = attributesToProps(computedAttributes);

    return (
      <img
        {...props}
        src={getAbsoluteImageUrl(computedAttributes.src)}
        alt={computedAttributes.alt}
      />
    );
  }

  if (element.name === 'figure' && element.attribs.class === 'media') {
    return (
      <figure className={element.attribs.class || ''} style={{ margin: 0 }}>
        {domToReact(element.children, { replace: wrappedReplacer })}
      </figure>
    );
  }

  if (element.name === 'figure' && element.attribs.class === 'table') {
    return <>{domToReact(element.children, { replace: wrappedReplacer })}</>;
  }

  if (element.name === 'table') {
    return (
      <TableContainer component={Paper}>
        <Table>
          {domToReact(element.children, { replace: wrappedReplacer })}
        </Table>
      </TableContainer>
    );
  }

  if (element.name === 'button') {
    const buttonProps = attributesToProps(element.attribs);
    const allowedPropValues = {
      variant: ['contained', 'outlined', 'text'],
      color: ['primary', 'secondary', 'success', 'info', 'warning', 'error'],
      size: ['small', 'medium', 'large'],
    };

    const getAllowedButtonPropValue = (inputProps, prop) => {
      const value = inputProps[`data-${prop}`];
      return allowedPropValues[prop].includes(value) ? value : null;
    };

    const variant =
      getAllowedButtonPropValue(buttonProps, 'variant') || 'contained';

    const color =
      getAllowedButtonPropValue(buttonProps, 'color') || 'secondary';

    const size = getAllowedButtonPropValue(buttonProps, 'size') || 'large';

    const href = buttonProps['data-href'] || undefined;

    return (
      <Button
        href={href}
        variant={variant}
        color={color}
        size={size}
        style={{ margin: theme.spacing(4, 0), textDecoration: 'none' }}
        component={Link}>
        {domToReact(element.children)}
      </Button>
    );
  }

  if (
    element.name &&
    elementsToHandleNatively.includes(element.name?.toLowerCase())
  ) {
    return renderNative(element, wrappedReplacer);
  }

  return null;
}

export function wrapDefaultRichTextReplacer(replacer) {
  return function wrappedRichTextReplacer(element) {
    const customResult = replacer(element, wrappedRichTextReplacer);

    if (customResult) {
      return customResult;
    }

    const defaultResult = richTextReplacer(element, wrappedRichTextReplacer);

    return defaultResult;
  };
}
